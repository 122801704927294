var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-10", attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-type-form-card", {
            attrs: { duct: _vm.duct },
            on: { update: _vm.updateHITTypeParams }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-form-card", {
            attrs: { prjName: _vm.prjName },
            on: { update: _vm.updateHITParams }
          })
        ],
        1
      ),
      _vm.projectHasDiff
        ? _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-alert",
                { attrs: { border: "left", type: "error" } },
                [
                  _vm._v(
                    " Seems like your project has been updated since last build. "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "white",
                        outlined: "",
                        loading: _vm.rebuildingProject
                      },
                      on: { click: _vm.rebuildProject }
                    },
                    [_vm._v(" Rebuild ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "10" } },
            [
              _c("post-hits-button", {
                attrs: {
                  duct: _vm.duct,
                  prjName: _vm.prjName,
                  HITTypeParams: _vm.HITTypeParams,
                  HITParams: _vm.HITParams,
                  numCreateHITs: _vm.numCreateHITs,
                  credentials: _vm.credentials,
                  createNew: _vm.createNew,
                  chosenExstHITTypeId: _vm.chosenExstHITTypeId
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }