var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Assign HIT Type "),
          _c("help-button", { attrs: { name: "QualificationRequirements" } })
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-radio-group",
            {
              staticClass: "mt-0",
              attrs: { fixed: "", top: "", "hide-details": "" },
              model: {
                value: _vm.createNew,
                callback: function($$v) {
                  _vm.createNew = $$v
                },
                expression: "createNew"
              }
            },
            [
              _c("v-radio", {
                attrs: { label: "Create new HIT type", value: true }
              }),
              _c("v-radio", {
                attrs: { label: "Choose from existing HIT type", value: false }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.createNew
        ? _c(
            "div",
            [
              _c("v-card-subtitle", [_c("b", [_vm._v("Existing HIT Types")])]),
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c(
                              "tr",
                              { staticStyle: { "background-color": "#eee" } },
                              [
                                _c("th"),
                                _c("th", [_vm._v("Name")]),
                                _c("th", [_vm._v("HIT Type ID")]),
                                _c("th", [_vm._v("Action")])
                              ]
                            )
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.exstHITTypes, function(params, htid) {
                              return _c("tr", { key: htid }, [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ma-0 pa-0",
                                        attrs: {
                                          dense: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.chosenExstHITTypeId,
                                          callback: function($$v) {
                                            _vm.chosenExstHITTypeId = $$v
                                          },
                                          expression: "chosenExstHITTypeId"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { value: htid }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v(_vm._s(params.Title))]),
                                _c("td", [_vm._v(_vm._s(htid))]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          text: "",
                                          color: "indigo"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyToNew(htid)
                                          }
                                        }
                                      },
                                      [_vm._v(" Copy to new ")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  4024575847
                )
              }),
              _c("v-divider")
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-subtitle",
        [
          _c("b", [_vm._v("HIT Type Params")]),
          _vm._v(" "),
          _c("help-button", { attrs: { name: "CreateHITType" } })
        ],
        1
      ),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", { staticStyle: { "background-color": "#eee" } }, [
                    _c("th", [_vm._v("Key")]),
                    _c("th", [_vm._v("Value")])
                  ])
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.HITTypeParamOptions, function(option) {
                      return _c("tr", { key: option.name }, [
                        _c("td", [_vm._v(_vm._s(option.name))]),
                        _vm.createNew
                          ? _c(
                              "td",
                              [
                                _c(
                                  "v-text-field",
                                  _vm._b(
                                    {
                                      attrs: { dense: "", "hide-details": "" },
                                      model: {
                                        value: _vm.HITTypeParams[option.name],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.HITTypeParams,
                                            option.name,
                                            $$v
                                          )
                                        },
                                        expression: "HITTypeParams[option.name]"
                                      }
                                    },
                                    "v-text-field",
                                    option.attrs,
                                    false
                                  )
                                )
                              ],
                              1
                            )
                          : _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.HITTypeParams[option.name]) +
                                  " "
                              )
                            ])
                      ])
                    }),
                    _vm._l(_vm.visibleQualificationRequirements, function(
                      qualItem,
                      qualIndex
                    ) {
                      return _c(
                        "tr",
                        { key: "QualificationRequirements-" + qualIndex },
                        [
                          _c("td", [
                            _vm._v(
                              " QualificationRequirements - " +
                                _vm._s(qualIndex + 1) +
                                " "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c(
                                  "tbody",
                                  [
                                    _c("tr", [
                                      _c("td", [_vm._v("QualificationTypeId")]),
                                      _vm.createNew
                                        ? _c(
                                            "td",
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  items: _vm.allQualIds
                                                },
                                                model: {
                                                  value:
                                                    qualItem[
                                                      "QualificationTypeId"
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      qualItem,
                                                      "QualificationTypeId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "qualItem['QualificationTypeId']"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  qualItem[
                                                    "QualificationTypeId"
                                                  ]
                                                ) +
                                                " "
                                            )
                                          ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("Comparator")]),
                                      _vm.createNew
                                        ? _c(
                                            "td",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  items:
                                                    _vm.qualRequirementOptions[
                                                      "Comparator"
                                                    ]
                                                },
                                                model: {
                                                  value: qualItem["Comparator"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      qualItem,
                                                      "Comparator",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "qualItem['Comparator']"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(qualItem["Comparator"]) +
                                                " "
                                            )
                                          ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("IntegerValues")]),
                                      _vm.createNew
                                        ? _c(
                                            "td",
                                            [
                                              _c("v-combobox", {
                                                attrs: {
                                                  multiple: "",
                                                  dense: "",
                                                  "small-chips": "",
                                                  "hide-details": "",
                                                  "append-icon": "",
                                                  rules: [_vm.rules.numbers]
                                                },
                                                model: {
                                                  value:
                                                    qualItem["IntegerValues"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      qualItem,
                                                      "IntegerValues",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "qualItem['IntegerValues']"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  qualItem["IntegerValues"]
                                                ) +
                                                " "
                                            )
                                          ])
                                    ]),
                                    _vm._l(qualItem.LocaleValues, function(
                                      localeItem,
                                      localeIndex
                                    ) {
                                      return _c(
                                        "tr",
                                        { key: "LocaleValues-" + localeIndex },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              "LocaleValues - " +
                                                _vm._s(localeIndex + 1)
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c("v-simple-table", {
                                                attrs: { dense: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function() {
                                                        return [
                                                          _c("tbody", [
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Country"
                                                                )
                                                              ]),
                                                              _vm.createNew
                                                                ? _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              localeItem.Country,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                localeItem,
                                                                                "Country",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "localeItem.Country"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          localeItem.Country
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "Subdivision"
                                                                )
                                                              ]),
                                                              _vm.createNew
                                                                ? _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              localeItem.Subdivision,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                localeItem,
                                                                                "Subdivision",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "localeItem.Subdivision"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c("td", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          localeItem.Subdivision
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                            ])
                                                          ])
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    _vm.createNew
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getNumLocaleValues(
                                                      qualItem
                                                    )
                                                  ) + " LocaleValues"
                                                )
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.pushLocaleValues(
                                                        qualItem
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.popLocaleValues(
                                                        qualItem
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-minus")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("td")
                                        ])
                                      : _vm._e(),
                                    _c("tr", [
                                      _c("td", [_vm._v("ActionsGuarded")]),
                                      _vm.createNew
                                        ? _c(
                                            "td",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  items:
                                                    _vm.qualRequirementOptions[
                                                      "ActionsGuarded"
                                                    ]
                                                },
                                                model: {
                                                  value:
                                                    qualItem["ActionsGuarded"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      qualItem,
                                                      "ActionsGuarded",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "qualItem['ActionsGuarded']"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  qualItem["ActionsGuarded"]
                                                ) +
                                                " "
                                            )
                                          ])
                                    ])
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    _vm.createNew
                      ? _c("tr", [
                          _c(
                            "td",
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.visibleQualificationRequirements.length
                                  ) +
                                    " QualificationRequirement" +
                                    _vm._s(
                                      _vm.visibleQualificationRequirements
                                        .length > 1
                                        ? "s"
                                        : ""
                                    )
                                )
                              ]),
                              _c("help-button", {
                                attrs: {
                                  "x-small": "",
                                  name: "QualificationRequirements"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-small": "", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.pushQualRequirements()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              ),
                              _vm.visibleQualificationRequirements.length
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { "x-small": "", icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.popQualRequirements()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-minus")])],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-snackbar", { ref: "snackbar" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }