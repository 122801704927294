var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-5" },
    [
      _c("v-card-title", [
        _vm._v("Create HITs for"),
        _c("span", { staticClass: "pl-2", staticStyle: { color: "red" } }, [
          _vm._v(_vm._s(_vm.prjName))
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _c("b", [_vm._v("# of HITs to post:")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      type: "number",
                      min: "0",
                      step: "1"
                    },
                    model: {
                      value: _vm.numCreateHITs,
                      callback: function($$v) {
                        _vm.numCreateHITs = _vm._n($$v)
                      },
                      expression: "numCreateHITs"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-subtitle",
        [
          _c("b", [_vm._v("HIT Params")]),
          _c("help-button", { attrs: { name: "CreateHITWithHITType" } })
        ],
        1
      ),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", { staticStyle: { "background-color": "#eee" } }, [
                    _c("th", [_vm._v("Key")]),
                    _c("th", [_vm._v("Value")])
                  ])
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("MaxAssignments")]),
                    _c(
                      "td",
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            disabled: "",
                            type: "number",
                            min: "1",
                            step: "1"
                          },
                          model: {
                            value: _vm.HITParams.MaxAssignments,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.HITParams,
                                "MaxAssignments",
                                _vm._n($$v)
                              )
                            },
                            expression: "HITParams.MaxAssignments"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("LifetimeInSeconds")]),
                    _c(
                      "td",
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            type: "number",
                            min: "0",
                            step: "10"
                          },
                          model: {
                            value: _vm.HITParams.LifetimeInSeconds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.HITParams,
                                "LifetimeInSeconds",
                                _vm._n($$v)
                              )
                            },
                            expression: "HITParams.LifetimeInSeconds"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("RequesterAnnotation")]),
                    _c(
                      "td",
                      [
                        _c("v-text-field", {
                          attrs: { dense: "", "hide-details": "" },
                          model: {
                            value: _vm.HITParams.RequesterAnnotation,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.HITParams,
                                "RequesterAnnotation",
                                $$v
                              )
                            },
                            expression: "HITParams.RequesterAnnotation"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }